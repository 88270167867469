import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { GiftsService } from '../serivce/gifts.service';
import { GiftsDeleteDialogComponent } from './gifts-delete-dialog/gifts-delete-dialog.component';

@Component({
  selector: 'app-gifts',
  templateUrl: './gifts.component.html',
  styleUrls: ['./gifts.component.css']
})
export class GiftsComponent implements OnInit {

  constructor(
    private service: GiftsService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataResult;
  displayedColumns =
/*    ['Index', "NameStudent", 'Title', 'Image', 'Description', 'Actions']*/
    ['Index', "NameStudent", 'Title',  'Description', 'Actions']
  loading: boolean = false;

  ngOnInit(): void {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loaddata();
      });
    }
    else {
      this.loaddata();
    }
  
  }
  loaddata() {
    debugger
    this.service.getAllGifts().subscribe(res => {
      this.dataResult = res.data;
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delGift(data) {
    //Show dialog delete
    debugger
    var model = { id: data }
    const dialogRef = this.dialog.open(GiftsDeleteDialogComponent, {
      width: '500px',
      data: { model }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loaddata();
    });
  }
}
