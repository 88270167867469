import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { teacher } from '../model/teacher';
@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class ClassService {
  readonly APIUrl = "https://api.stnhd.com/api/"
  //readonly APIUrl = "https://localhost:44301/api/"
  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Class/getAllStar");
  }
  createClass(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Class/CreateClass", model);
  }
  sendActiveCode(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Class/SendActiveCode", model);
  }
  getAllClass(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Class/getAllClass");
  }
  delClass(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Class/delClass", model);
  }
  editClass(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Class/editClass", model);
  }
  GetInfo(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Class/GetInfoClass", model);
  }
  getClassForTeacher(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Class/GetClassForTeacher", model);
  }
}
