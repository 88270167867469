import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TicketService } from '../../serivce/ticket.service';

@Component({
  selector: 'app-ticket-create-dialog',
  templateUrl: './ticket-create-dialog.component.html',
  styleUrls: ['./ticket-create-dialog.component.css']
})
export class TicketCreateDialogComponent implements OnInit {

  constructor(private storage: AngularFireStorage, private spinner: NgxSpinnerService, private service: TicketService, private toastr: ToastrService,
    private router: Router) { }
  model = {
    Title: '',
    Content: '',
    TypeTicket: 1,
    Img: '',
    CreatedBy: ''
  }
  fb;
  downloadURL: Observable<string>;
  ngOnInit() {

  }
  onFileSelected(event) {
    this.spinner.show();
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `RoomsImages/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`RoomsImages/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
              $('#img').attr("src", url);
              this.spinner.hide();
            }
          });
        })
      )
      .subscribe(url => {
      });
  }
  onSubmit() {
    debugger
    this.spinner.show();
    this.model.Img = this.fb;
    this.service.createTicket(this.model).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/ticket');
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
    /*   }*/
  }
}
