import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ParentsService } from '../serivce/parents.service';
import { ParentDeleteDialogComponent } from './parent-delete-dialog/parent-delete-dialog.component';
import * as XLSX from 'xlsx';
import { StudentsService } from '../serivce/students.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginComponent } from '../auth/login/login.component';
@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.css']
})
export class ParentsComponent implements OnInit {
  filename: '';
  stringJson: string;
  selecteds: any;
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataResult;
  displayedColumns =
    ['Index', 'FullName', 'Phone',
      'Gender', 'Address', 'ProvinceName', 'DistrictName', 'BankAccountNo', 'BankName' ,'Actions']

  loading: boolean = false;
  constructor(private parentService: ParentsService,
    private spinner: NgxSpinnerService,
    private studentsSevice: StudentsService,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog  ) { }

  ngOnInit() {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loaddata();
      });
    }
    else {
      this.loaddata();
    }
  }
  loaddata() {
    this.loading = true;
    this.parentService.getAll().subscribe(res => {
      console.log(res.data)
      this.dataResult = res.data;
      this.loading = false;
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  delParents(data) {
    //Show dialog delete
    debugger
    var model = { id: data }
    const dialogRef = this.dialog.open(ParentDeleteDialogComponent, {
      width: '500px',
      data: { model }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loaddata();
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  removeAccents(str) {
    var AccentsMap = [
      "aàảãáạăằẳẵắặâầẩẫấậ",
      "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
      "dđ", "DĐ",
      "eèẻẽéẹêềểễếệ",
      "EÈẺẼÉẸÊỀỂỄẾỆ",
      "iìỉĩíị",
      "IÌỈĨÍỊ",
      "oòỏõóọôồổỗốộơờởỡớợ",
      "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
      "uùủũúụưừửữứự",
      "UÙỦŨÚỤƯỪỬỮỨỰ",
      "yỳỷỹýỵ",
      "YỲỶỸÝỴ"
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  uploadfile(files) {
    this.spinner.show();

    debugger
    if (files.length == 0) return;
    this.filename = this.removeAccents(files[0].name);
    var namefile = files[0].name.slice(((files[0].name.length) - 4), (files[0].name.length));
    if (namefile == 'xlsx' || namefile == 'xls') {
      if (files[0]) {
        let rowObjet
        this.selecteds = files[0]
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(this.selecteds);
        var self = this;
        fileReader.onload = function (e) {
          var data = fileReader.result;
          let workbook = XLSX.read(data, { type: "binary" });
          var sheetName = workbook.SheetNames[0];
          rowObjet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          if (rowObjet.length > 0) {
            debugger
            self.stringJson = JSON.stringify(rowObjet);
            self.createforImport(self.stringJson);
          }
        }
      }
    }
    else {
      this.toastr.error("File import phải là file excel", "Không thành công");
    }

  }
  createforImport(json) {

    var models = {
      jsons: (json)
    }
 
    this.studentsSevice.createStudentsForImport(models).subscribe((res) => {
      this.spinner.hide();

      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.loaddata();
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    });
  }
  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  download(data, filename) {
    var file = new Blob([this.s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
}
