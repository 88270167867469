import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { ClassService } from '../serivce/class.service';
import { ClassDeleteDialogComponent } from './class-delete-dialog/class-delete-dialog.component';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.css']
})
export class ClassComponent implements OnInit {

  constructor(private classService: ClassService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private router: Router) { }
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataResult;
  displayedColumns =
    ['Index', 'Code', 'Name', 'OpeningDay', 'FullName', 'Actions']
  loading: boolean = false;
  ngOnInit() {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loaddata();
      });
    }
    else {
      this.loaddata();
    }

  }
  loaddata() {
    debugger
    this.classService.getAllClass().subscribe(res => {
      console.log(res.data)
      this.dataResult = res.data;
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  deleteClass(inputcode) {
    //Show dialog delete
    debugger
    var model = { Id: inputcode }
    const dialogRef = this.dialog.open(ClassDeleteDialogComponent, {
      width: '500px',
      data: { model }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loaddata();
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
