import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from '../../serivce/teacher.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-teacher-create-dialog',
  templateUrl: './teacher-create-dialog.component.html',
  styleUrls: ['./teacher-create-dialog.component.css']
})
export class TeacherCreateDialogComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,

    private service: TeacherService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  model = {
    TeacherType: 0,
    FullName: '',
    Phone: '',
    Email: '',
    WorkDate: new Date(),
    stringWorkDate: '',
    Gender: '1',
    LeaveDate: new Date(),
    stringLeaveDate: '',
    Facebook: ''
  }
  ngOnInit() {
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.FullName == '') {
      this.toastr.warning("Chưa nhập tên giáo viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Phone == '') {
      this.toastr.warning("Chưa nhập số điện thoại giáo viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (regexp.test(this.model.Phone) == false && this.model.Phone != '') {
      this.toastr.warning("Số điện thoại phải là số và có 10 số", "Sai dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Email == '') {
      this.toastr.warning("Chưa nhập email giáo viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    /* if (this.Validate() == true) {*/
    var models = {
      TeacherType: this.model.TeacherType,
      FullName: this.model.FullName,
      Phone: this.model.Phone,
      Email: this.model.Email,
      stringWorkDate: this.convert(this.model.WorkDate),
      Gender: this.model.Gender,
      stringLeaveDate: this.convert(this.model.LeaveDate),
      Facebook: this.model.Facebook
    }
    this.spinner.show();
    this.service.createTeacher(models).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/teacher');
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
    /*   }*/
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

}
