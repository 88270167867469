import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DistrictsService } from '../../serivce/districts.service';
import { ParentsService } from '../../serivce/parents.service';
import { ProvincesService } from '../../serivce/provinces.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
export interface Array {

  value: string;
  viewValue: string;

}
@Component({
  selector: 'app-parents-create-dialog',
  templateUrl: './parents-create-dialog.component.html',
  styleUrls: ['./parents-create-dialog.component.css']
})
export class ParentsCreateDialogComponent {

  constructor(private provinceService: ProvincesService,
    private spinner: NgxSpinnerService,
    private districtService: DistrictsService,
    private parentService: ParentsService,
    private toastr: ToastrService,
    private router: Router,
    private storage: AngularFireStorage) { }
  loading: boolean = false;
  model = {
    FullName: '',
    Email: '',
    Phone: '',
    Facebook: '',
    Gender: 'Nam',
    JobDesc: '',
    ProvinceId: 0,
    DistrictId: 0,
    Beneficiary: '',
    BankAccountNo: '',
    BankName: '',
    AvartarImg: '',
    Address: '',
    IdNo: ''
  }
  title = "cloudsSorage";
  selectedFile: File = null;
  fb;
  downloadURL: Observable<string>;
  dropdownProvince: Array[] = [];
  dropdownDistrict: Array[] = [];
  result;
  ngOnInit() {
    debugger
    this.loadProvince();
  }
  loadProvince() {
    this.provinceService.getListProvince().subscribe((res) => {
      this.result = res.data;
      this.dropdownProvince = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { value: this.result[i].id, viewValue: this.result[i].name };
        this.dropdownProvince.push(object);
      }
    });
  }
  changeProvince(data) {
    debugger
    var models = {
      ProvinceId: data
    }
    this.districtService.getListDistrict(models).subscribe((res) => {
      this.result = res.data;
      this.dropdownDistrict = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { value: this.result[i].id, viewValue: this.result[i].name };
        this.dropdownDistrict.push(object);
      }
    });
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.FullName == '') {
      this.toastr.warning("Chưa nhập Tên Phụ Huynh", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Phone == '') {
      this.toastr.warning("Chưa nhập số điện thoại", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (regexp.test(this.model.Phone) == false && this.model.Phone != '') {
      this.toastr.warning("Số điện thoại phải là số và có 10 số", "Sai dữ liệu");
      ErrorCount = ErrorCount + 1;
    }

    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    /* if (this.Validate() == true) {*/
    debugger
    var models = {
      FullName: this.model.FullName,
      Email: this.model.Email,
      Phone: this.model.Phone,
      Facebook: this.model.Facebook,
      Gender: this.model.Gender,
      JobDesc: this.model.JobDesc,
      ProvinceId: this.model.ProvinceId,
      DistrictId: this.model.DistrictId,
      Beneficiary: this.model.Beneficiary,
      BankAccountNo: this.model.BankAccountNo,
      BankName: this.model.BankName,
      AvartarImg: this.fb,
      Address: this.model.Address,
      IdNo: this.model.IdNo
    }
    this.spinner.show();
    this.parentService.createParents(models).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/parents');
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
    /*   }*/

  }
  onFileSelected(event) {

    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `RoomsImages/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`RoomsImages/${n}`, file);
    this.loading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
              $('#img').attr("src", url);
              this.loading = false;
            }

            console.log(this.fb);
          });
        })
      )
      .subscribe(url => {
        //if (url) {
        //  this.urls = url.toString();
        //  console.log(this.urls);
        //}
      });
  }
}
