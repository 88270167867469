import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { teacher } from '../model/teacher';
@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class StudentsService {
  readonly APIUrl = "https://api.stnhd.com/api/"
  //readonly APIUrl = "https://localhost:44301/api/"
  constructor(private http: HttpClient) { }

  getListStudents(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Students/getAllStudents");
  }
  sendActiveCodeForStudents(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/SendActiveCodeForStudents", model);
  }
  createStudents(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/CreateStudents", model);
  }
  createStudentsForImport(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/CreateStudentsForImport", model);
  }
  editStudents(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/EditStudents", model);
  }
  delStudent(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/delStudent", model);
  }
  getAllStudentById(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/getAllStudentById", model);
  }
  getAllStarClass(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/getAllStarClass", model);
  }
  getStartForStudents(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Students/getStartForStudents", model);
  }
}
