import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeacherjobsService {
  readonly APIUrl = "https://api.stnhd.com/api/"
  //readonly APIUrl = "https://localhost:44301/api/"
  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "TeacherJobs/getAllTeacherJobs");
  }
  createTeacherJobs(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "TeacherJobs/CreateTeacherJobs", model);
  }
  delTeacherJobs(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "TeacherJobs/DelTeacherJobs", model);
  }
  editTeacherJobs(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "TeacherJobs/EditTeacherJobs", model);
  }

}
