import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { teacher } from '../model/teacher';
@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  readonly APIUrl = "https://api.stnhd.com/api/"
  //readonly APIUrl = "https://localhost:44301/api/"
  constructor(private http: HttpClient) { }
  getAllTeacher(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Teacher/getAll");
  }
  getListTeacher(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Teacher/getAllTeacher");
  }
  getListTeacherfigurant
    (): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Teacher/getAllTeacherFigurant");
  }
  createTeacher(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Teacher/CreateTeacher", model);
  }
  editTeacher(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Teacher/EditTeacher", model);
  }
  delTeacher(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Teacher/delTeacher", model);
  }
  getInfoTeacher(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Teacher/getInfoTeacher", model);
  }
}

