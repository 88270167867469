import { EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { MatDialog, MatStepper, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { JobsService } from '../serivce/jobs.service';
import { TeacherjobsService } from '../serivce/teacherjobs.service';
import { DeleteTeacherjobsDialogComponent } from './delete-teacherjobs-dialog/delete-teacherjobs-dialog.component';
@Component({
  selector: 'app-teacherjobs',
  templateUrl: './teacherjobs.component.html',
  styleUrls: ['./teacherjobs.component.css']
})
export class TeacherjobsComponent implements OnInit {

  constructor(
    private service: TeacherjobsService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataResult;
  displayedColumns =
    ['Index', "NameJobs", 'NameTeacher', 'Description', 'Actions']
  loading: boolean = false;

  ngOnInit(): void {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loaddata();
      });
    }
    else {
      this.loaddata();
    }
  }
  loaddata() {


    debugger
    this.service.getAll().subscribe(res => {
      this.dataResult = res.data;
      console.log(res.data)
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteTeacherJobs(id) {
    //Show dialog delete
    debugger
    var model = { Id: id }
    const dialogRef = this.dialog.open(DeleteTeacherjobsDialogComponent, {
      width: '500px',
      data: { model }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loaddata();
    });
  }
}
