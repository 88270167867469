import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../auth/login/login.component';
import { ClassService } from '../serivce/class.service';
import { TeacherService } from '../serivce/teacher.service';
export interface ArrayTeacher {
  TeacherId: string;
  viewValue: string;
}
export interface ArrayClass {
  ClassId: string;
  viewValue: string;
}
@Component({
  selector: 'app-workreport',
  templateUrl: './workreport.component.html',
  styleUrls: ['./workreport.component.css']
})
export class WorkreportComponent implements OnInit {
  result;
  model = {
    TeacherId: 0,
    ClassId: 0,
    FromDate: new Date()
  }
  teacher: ArrayTeacher[] = [];
  class: ArrayClass[] = [];

  displayedColumns =
    ['Index', "NameStudent", 'Description', 'NameTeacher', 'Actions']
  loading: boolean = false;

  constructor(private teacherService: TeacherService, private classService: ClassService, private dialog: MatDialog,) { }

  ngOnInit() {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loadDataTeacher();
      });
    }
    else {
      this.loadDataTeacher();
    }
  }
  loadDataTeacher() {
    debugger
    this.teacherService.getListTeacher().subscribe((res) => {
      this.result = res.data;
      this.teacher = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { TeacherId: this.result[i].id, viewValue: this.result[i].fullName };
        this.teacher.push(object);
      }
    });
  }
  changeTeacher(data) {
    var model = {
      TeacherId: data
    }
    this.class = [];
    this.classService.getClassForTeacher(model).subscribe((res) => {
      this.result = res.data;
      for (var i = 0; i < this.result.length; i++) {
        var object = { ClassId: this.result[i].id, viewValue: this.result[i].name };
        this.class.push(object);
      }
    });
  }
  getworkreport() {
    debugger
    localStorage.setItem("jwt", "null");
/*    localStorage.removeItem("jwt")*/
    const token: string = localStorage.getItem("jwt")
    //var model = {
    //  stringCreateDate: this.convert(this.model.FromDate),

    //}
    //this.class = [];
    //this.classService.getClassForTeacher(model).subscribe((res) => {
    //  this.result = res.data;
    //  for (var i = 0; i < this.result.length; i++) {
    //    var object = { ClassId: this.result[i].id, viewValue: this.result[i].name };
    //    this.class.push(object);
    //  }
    //});
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
