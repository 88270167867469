import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { StudentactiveService } from '../serivce/studentactive.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private studentService: StudentactiveService,
  ) { }
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataSourceComplete: MatTableDataSource<any>;
  dataSourceWasUsed: MatTableDataSource<any>;
  dataResult;
  model = {
    IDNo: ''
  }
  displayedColumns =
    /*    ['Index', "NameStudent", 'Title', 'Image', 'Description', 'Actions']*/
    ['Index', 'SBD', 'MKH', 'userName', 'Password', 'NameParent', 'NameSon', 'PhoneNumber', 'IsAcive', 'Note']
  displayedColumnsComplete =
    /*    ['Index', "NameStudent", 'Title', 'Image', 'Description', 'Actions']*/
    ['Index', 'QualityName', 'FinishDate', 'TotalDay']



  loading: boolean = false;


  ngOnInit() {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  onSubmit() {
    this.studentService.getReport(this.model).subscribe(res => {
      debugger
      this.dataResult = res.data;
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult.objReport);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


    this.dataSourceComplete = new MatTableDataSource(this.dataResult.allStarComplete);
    this.dataSourceComplete.paginator = this.paginator;
    this.dataSourceComplete.sort = this.sort;



    this.dataSourceWasUsed = new MatTableDataSource(this.dataResult.allStarWasUsed);
    this.dataSourceWasUsed.paginator = this.paginator;
    this.dataSourceWasUsed.sort = this.sort;
  }
}
