// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //urlapi: 'https://crm.phattien.com/',
  //urlapi: 'https://csi.phattien.com/',
  urlapi: 'http://localhost:9955/',
  production: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  firebaseConfig : {
    apiKey: "AIzaSyDY3sjBbaKyaVri2jbgQMPD4WIdR3WiVMU",
    authDomain: "test-uploadfile-666fa.firebaseapp.com",
    databaseURL: "https://test-uploadfile-666fa-default-rtdb.firebaseio.com/",
    projectId: "test-uploadfile-666fa",
    storageBucket: "test-uploadfile-666fa.appspot.com",
    messagingSenderId: "710563881560",
    appId: "1:710563881560:web:02f4199fe0db52d56d202d",
    measurementId: "G-E5WYYFK0S9"
  }
};
