import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from '../../serivce/teacher.service';

@Component({
  selector: 'app-teacher-delete-dialog',
  templateUrl: './teacher-delete-dialog.component.html',
  styleUrls: ['./teacher-delete-dialog.component.css']
})
export class TeacherDeleteDialogComponent implements OnInit {
  datas: any;
  constructor(private spinner: NgxSpinnerService,

    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: TeacherService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
  ) {
    this.datas = data;
  }

  ngOnInit() {
  }
  deleteTeacher() {
    debugger
    var model = {
      Code: this.datas.model.code
    }
    this.spinner.show();
    this.service.delTeacher(model).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success("Xóa giáo viên thành công", "Thành công");
        this.dialogRef.close();
      }
      else {
        this.toastr.error("Xóa giáo viên không thành công", "Thành công");
      }
   
    })
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
