import { EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { MatDialog, MatStepper, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { TeacherService } from '../serivce/teacher.service';
import { TeacherCreateDialogComponent } from './teacher-create-dialog/teacher-create-dialog.component';
import { TeacherDeleteDialogComponent } from './teacher-delete-dialog/teacher-delete-dialog.component';
@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {

  constructor(
    private service: TeacherService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataResult;
  displayedColumns =
    ['Index', "Code", 'TeacherType', 'FullName', 'Phone', 'Actions']
  loading: boolean = false;

  ngOnInit(): void {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loaddata();
      });
    }
    else {
      this.loaddata();
    }
  }
  loaddata() {
    debugger
    this.service.getAllTeacher().subscribe(res => {
      this.dataResult = res.data;
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  deleteTeacher(Code, fullName) {
    debugger
    var model = {
      code: Code,
      name: fullName

    }
    const dialogref = this.dialog.open(TeacherDeleteDialogComponent, {
      width: '500px',
      data: { model }
    });
    dialogref.afterClosed().subscribe(result => {
      this.loaddata();
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
