import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from '../../serivce/teacher.service';

@Component({
  selector: 'app-teacher-edit-dialog',
  templateUrl: './teacher-edit-dialog.component.html',
  styleUrls: ['./teacher-edit-dialog.component.css']
})
export class TeacherEditDialogComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,

    private service: TeacherService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  Code: string;
  model = {
    TeacherType: 0,
    FullName: '',
    Phone: '',
    Email: '',
    WorkDate: new Date(),
    stringWorkDate: '',
    Gender: '1',
    LeaveDate: new Date(),
    stringLeaveDate: '',
    Facebook: ''
  }
  ngOnInit() {
    debugger
    this.Code = (this.route.snapshot.params['Code'])
    this.model.FullName = this.route.snapshot.params['FullName']
    this.model.TeacherType = parseInt(this.route.snapshot.params['TeacherType'])
    this.model.Phone = (this.route.snapshot.params['Phone'])
    this.model.Email = (this.route.snapshot.params['Email'])
    this.model.WorkDate = (this.route.snapshot.params['WorkDate'])
    this.model.Gender = (this.route.snapshot.params['Gender'])
    this.model.LeaveDate = (this.route.snapshot.params['LeaveDate'])
    this.model.Facebook = (this.route.snapshot.params['Facebook'])
    console.log(this.model)
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.FullName == '') {
      this.toastr.warning("Chưa nhập tên giáo viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Phone == '') {
      this.toastr.warning("Chưa nhập số điện thoại giáo viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (regexp.test(this.model.Phone) == false && this.model.Phone != '') {
      this.toastr.warning("Số điện thoại phải là số và có 10 số", "Sai dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Email == '') {
      this.toastr.warning("Chưa nhập email giáo viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    /*    if (this.Validate() == true) {*/
    var models = {
      Code: this.Code,
      TeacherType: this.model.TeacherType,
      FullName: this.model.FullName,
      Phone: this.model.Phone,
      Email: this.model.Email,
      stringWorkDate: this.convert(this.model.WorkDate),
      Gender: this.model.Gender,
      stringLeaveDate: this.convert(this.model.LeaveDate),
      Facebook: this.model.Facebook
    }
    this.spinner.show();
    this.service.editTeacher(models).subscribe(res => {
      debugger
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/teacher');
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
    /*  }*/
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
