import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { StudentsService } from '../../serivce/students.service';

@Component({
  selector: 'app-delete-student-dialog',
  templateUrl: './delete-student-dialog.component.html',
  styleUrls: ['./delete-student-dialog.component.css']
})
export class DeleteStudentDialogComponent implements OnInit {

  datas: any;
  constructor(private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: StudentsService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
  ) {
    this.datas = data;
    //this.dataCode = data.Code;
    //this.dataFullName = data.FullName;
  }

  ngOnInit() {
  }
  delStudents() {
    debugger
    var models = {
      id: this.datas.model.id,
      FullName: this.datas.model.FullName
    }
    this.spinner.show();
    this.service.delStudent(models).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.dialogRef.close();
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
