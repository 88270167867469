import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../serivce/class.service';
import { TeacherService } from '../../serivce/teacher.service';
export interface Array {

  value: string;
  viewValue: string;

}
export interface ArrayTeacher {

  TeacherId: string;
  viewValue: string;

}
@Component({
  selector: 'app-class-create-dialog',
  templateUrl: './class-create-dialog.component.html',
  /*  styleUrls: ['./class-create-dialog.component.css']*/
  encapsulation: ViewEncapsulation.None // Add this line
})
export class ClassCreateDialogComponent implements OnInit {

  constructor(
    private classService: ClassService,
    private teacherService: TeacherService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }
  model = {
    Name: '',
    OpenDay: new Date(),
    stringOpeningDay: '',
    StarId: 0,
    TeacherId: 0
  }
  result;
  star: Array[] = [];
  teacher: ArrayTeacher[] = [];
  dropdownStar = [];
  dropdownTeacher = [];
  selectedItemsStar = [];
  selectedItemsTeacher = [];
  dropdownSettingsStar: IDropdownSettings;
  dropdownSettingsTeacher: IDropdownSettings;
  ngOnInit() {
   
    this.dropdownSettingsStar = {
      singleSelection: false,
      idField: 'StarId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.dropdownSettingsTeacher = {
      singleSelection: false,
      idField: 'TeacherId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.loadDataStar();
    this.loadDataTeacher();
    this.loadDataTeacherfigurant();
  }
  loadDataStar() {
    debugger
    this.classService.getAll().subscribe((res) => {
      this.result = res.data;

      this.dropdownStar = [];

      for (var i = 0; i < this.result.length; i++) {
        var object = { StarId: this.result[i].id, viewValue: this.result[i].name };
        this.dropdownStar.push(object);
      }

    });
  }
  loadDataTeacher() {
    debugger
    this.teacherService.getListTeacher().subscribe((res) => {
      this.result = res.data;
      this.teacher = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { TeacherId: this.result[i].id, viewValue: this.result[i].fullName + ' - ' + this.result[i].nameType  };
        this.teacher.push(object);
      }
    });
  }
  loadDataTeacherfigurant() {
    debugger
    this.teacherService.getListTeacherfigurant().subscribe((res) => {
      this.result = res.data;
      this.dropdownTeacher = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { TeacherId: this.result[i].id, viewValue: this.result[i].fullName + ' - ' + this.result[i].nameType };
        this.dropdownTeacher.push(object);
      }
    });
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.Name == '') {
      this.toastr.warning("Chưa nhập Tên Lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.selectedItemsStar.length == 0 || this.selectedItemsStar == null) {
      this.toastr.warning("Bạn chưa chọn sao cho lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.selectedItemsTeacher.length == 0 || this.selectedItemsTeacher == null) {
      this.toastr.warning("Bạn chưa chọn giáo viên phụ cho lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.TeacherId == 0) {
      this.toastr.warning("Bạn chưa chọn giáo viên chính cho lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    debugger
  /*  if (this.Validate() == true) {*/
      var models = {
        Name: this.model.Name,
        stringOpeningDay: this.convert(this.model.OpenDay),
        StarId: this.model.StarId,
        TeacherId: this.model.TeacherId,
        selectedItemsStar: this.selectedItemsStar,
        selectedItemsTeacher: this.selectedItemsTeacher
      }
      this.spinner.show();
      this.classService.createClass(models).subscribe(res => {
        this.spinner.hide();
        if (res.status == 2) {
    
          this.toastr.success(res.content, "Thành công");
          this.router.navigateByUrl('/class');
        }
        else {
          this.toastr.error(res.content, "Thất bại");
        }
      })
/*    }*/
   
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
