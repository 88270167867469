import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../serivce/class.service';
import { JobsService } from '../../serivce/jobs.service';
import { StudentsService } from '../../serivce/students.service';
import { TeacherService } from '../../serivce/teacher.service';

@Component({
  selector: 'app-edit-teacherjobs-dialog',
  templateUrl: './edit-teacherjobs-dialog.component.html',
  styleUrls: ['./edit-teacherjobs-dialog.component.css']
})
export class EditTeacherjobsDialogComponent implements OnInit {
  model = {
    fullName: '',
    gender: '0',
    phone: '',
    email:''
  }
  modelClass = {
    ClassId: 0,
    StudentId: 0,
    JobsId: 0
  }
  modelJobs = {
    fromDate: new Date(),
    toDate: new Date(),
    description:''
  }
  jobs;
  class;
  students;
  Code: '';
  JobsId: number;
  TeacherId: number;
  constructor(private route: ActivatedRoute, private teacherService: TeacherService,
    private toastr: ToastrService, private router: Router,
    private classService: ClassService, private studentService: StudentsService,
    private jobsSerice: JobsService, private spinner: NgxSpinnerService) { }
  ngOnInit() {
    debugger
    this.Code = this.route.snapshot.params['Code'];
    this.JobsId = parseInt(this.route.snapshot.params['JobsId']);
    this.TeacherId = parseInt(this.route.snapshot.params['TeacherId']);
    this.getInfoTeacher()
    this.getClassByTeacher();
    this.getJobs();
  }
  getInfoTeacher() {
    var models = {
      Code: this.Code
    }
    this.teacherService.getInfoTeacher(models).subscribe(res => {
      this.model = res.data;
    })
  }
  getClassByTeacher() {
    var models = {
      TeacherId: this.TeacherId
    }
    this.classService.getClassForTeacher(models).subscribe(res => {
      this.class = [];
      debugger
      for (var i = 0; i < res.data.length; i++) {
        var object = { ClassId: res.data[i].id, viewValue: res.data[i].name };
        this.class.push(object);
      }
    })
  }
  changeClass(id) {
    var models = {
      Id: id
    }
    this.studentService.getAllStudentById(models).subscribe(res => {
      debugger
      this.students = [];
      debugger
      for (var i = 0; i < res.data.length; i++) {
        var object = { StudentId: res.data[i].id, viewValue: res.data[i].fullName };
        this.students.push(object);
        this.modelClass.StudentId = res.data[i].id
      }
    })
  }
  getJobs() {
    var models = {
      Id: this.JobsId,
      TeacherId: this.TeacherId
    }
    this.jobsSerice.GetAllJobById(models).subscribe(res => {
      debugger
      this.jobs = [];
      debugger
      for (var i = 0; i < res.data.length; i++) {
        var object = { JobsId: res.data[i].id, viewValue: res.data[i].name };
        this.jobs.push(object);
      }
    })
  }
  changeJobs(data) {
    var models = {
      Id: data
    }
    this.jobsSerice.GetInfoJobs(models).subscribe(res => {
      debugger
      this.modelJobs = res.data;
    })
  }
}

