import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../serivce/class.service';
import { JobsService } from '../../serivce/jobs.service';
import { TeacherService } from '../../serivce/teacher.service';
import { TeacherjobsService } from '../../serivce/teacherjobs.service';

@Component({
  selector: 'app-create-teacherjobs-dialog',
  templateUrl: './create-teacherjobs-dialog.component.html',
  styleUrls: ['./create-teacherjobs-dialog.component.css']
})
export class CreateTeacherjobsDialogComponent implements OnInit {
  dropdownSettingsTeacher: IDropdownSettings;
  result;
  jobs;
  model = {
    JobsId: 0
  }
  dropdownTeacher = [];
  selectedItemsTeacher = [];
  constructor(
    private service: TeacherjobsService,
    private teacherService: TeacherService,
    private Jobs: JobsService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService

  ) { }

  ngOnInit() {

    this.dropdownSettingsTeacher = {
      singleSelection: false,
      idField: 'TeacherId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.loadDataTeacher();
    this.loadDataJobs();
  }
  loadDataTeacher() {
    debugger

    this.teacherService.getListTeacher().subscribe((res) => {
      this.result = res.data;
      this.dropdownTeacher = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { TeacherId: this.result[i].id, viewValue: this.result[i].fullName + ' - ' + this.result[i].nameType  };
        this.dropdownTeacher.push(object);
      }
    });
  }
  loadDataJobs() {
    this.Jobs.getAll().subscribe((res) => {
      this.result = res.data;
      this.jobs = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { JobsId: this.result[i].id, viewValue: this.result[i].name };
        this.jobs.push(object);
      }
    });
  }
  onSubmit() {
    var models = {
      JobsId: this.model.JobsId,
      selectedItemsTeacher: this.selectedItemsTeacher
    }
    this.spinner.show();
    this.service.createTeacherJobs(models).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/teacherjobs');
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
  }
}
