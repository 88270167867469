import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TicketService {
  readonly APIUrl = "https://api.stnhd.com/api/"
  //readonly APIUrl = "https://localhost:44301/api/"
  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Ticket/getAll");
  }
  createTicket(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Ticket/CreateTicket", model);
  }
  delTicket(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Ticket/DelTicket", model);
  }
  editTicket(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Ticket/EditTicket", model);
  }
}
