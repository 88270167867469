import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../serivce/class.service';
import { ParentsService } from '../../serivce/parents.service';
import { StudentsService } from '../../serivce/students.service';


@Component({
  selector: 'app-edit-student-dialog',
  templateUrl: './edit-student-dialog.component.html',
  styleUrls: ['./edit-student-dialog.component.css']
})
export class EditStudentDialogComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    private studentService: StudentsService,
    private toastr: ToastrService,
    private router: Router,
    private parentService: ParentsService,
    private classService: ClassService,
    private route: ActivatedRoute
  ) { }
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  dropdownSettingsParent: IDropdownSettings;
  dropdownSettingsClass: IDropdownSettings;
  displayedColumns =
    ['Index', 'BasisName', 'QualityName', 'StarName', 'NoOfDay', 'StartProcessDate',
      'EndProcessDate', 'ProcessHeaderID']
  model = {
    Id: 0,
    Code: '',
    FullName: '',
    DateOfBirth: new Date(),
    stringDateOfBirth: '',
    StartDate: new Date(),
    stringStartDate: '',
    EndDate: new Date(),
    stringEndDate: '',
    ClassId: 0,
    ParentId: 0,
    SchoolName: '',
    SchoolClassId: 0,
    IdNo: '',
    ShirtSize: ''
  }
  result;
  dropdownParents = [];
  dropdownClass = [];
  selectedItemsClass = [];
  selectedItemsParent = [];
  dataSource: MatTableDataSource<any>;

  ngOnInit() {
    debugger
    this.model.FullName = this.route.snapshot.params['FullName']
    this.model.SchoolName = (this.route.snapshot.params['SchoolName'])
    this.model.DateOfBirth = (this.route.snapshot.params['DateOfBirth'])
    this.model.StartDate = (this.route.snapshot.params['StartDate'])
    this.model.EndDate = (this.route.snapshot.params['EndDate'])
    this.model.ParentId = parseInt(this.route.snapshot.params['ParentId'])
    this.model.SchoolClassId = parseInt(this.route.snapshot.params['SchoolClassId'])
    this.model.Code = (this.route.snapshot.params['Code'])
    this.model.IdNo = (this.route.snapshot.params['IdNo'])
    var object = { ParentId: this.model.ParentId, viewValue: (this.route.snapshot.params['ParentName']) };
    this.model.ShirtSize = (this.route.snapshot.params['ShirtSize'])
    this.selectedItemsParent.push(object)
    debugger
    var objects2 = { ClassId: this.model.SchoolClassId, viewValue: (this.route.snapshot.params['Nameclass']) };
    this.selectedItemsClass.push(objects2)

    this.dropdownSettingsParent = {
      singleSelection: true,
      idField: 'ParentId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.dropdownSettingsClass = {
      singleSelection: true,
      idField: 'ClassId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.loadDataParents();
    this.loadDataClass();
    this.getStartForStudents()
  }

  changeClass() {
    debugger
    var models = {
      SchoolClassId: this.selectedItemsClass[0].ClassId
    }
    debugger
    this.studentService.getAllStarClass(models).subscribe((res) => {

      this.result = res.data;
      this.loadDatasource();
    });
  }
  getStartForStudents() {
    var models = {
      Id: parseInt(this.route.snapshot.params['Id'])
    }
    this.studentService.getStartForStudents(models).subscribe(res => {
      this.result = res.data;
      console.log(res.data)
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  loadDataParents() {
    debugger
    this.parentService.getAll().subscribe((res) => {
      this.result = res.data;

      this.dropdownParents = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { ParentId: this.result[i].id, viewValue: this.result[i].fullName };
        this.dropdownParents.push(object);
      }

    });
  }
  loadDataClass() {
    debugger
    this.classService.getAllClass().subscribe((res) => {
      this.result = res.data;

      this.dropdownClass = [];
    
      for (var i = 0; i < this.result.length; i++) {
        var object = { ClassId: this.result[i].id, viewValue: this.result[i].name };
        this.dropdownClass.push(object);
      }

    });
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.FullName == '') {
      this.toastr.warning("Chưa nhập Họ tên học viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }

    if (this.model.ParentId == 0 || this.model.ParentId == null) {
      this.toastr.warning("Bạn chưa chọn phụ huynh cho học viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    //if (this.model.ClassId == 0 || this.model.ClassId == null) {
    //  this.toastr.warning("Bạn chưa lớp cho học viên", "Thiếu dữ liệu");
    //  ErrorCount = ErrorCount + 1;
    //}
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    debugger
    if (this.Validate() == true) {
      this.model.Id = parseInt(this.route.snapshot.params['Id']);
      this.model.stringDateOfBirth = this.convert(this.model.DateOfBirth);
      this.model.stringEndDate = this.convert(this.model.EndDate);
      this.model.stringStartDate = this.convert(this.model.StartDate);
      this.model.ParentId = parseInt(this.selectedItemsParent[0].ParentId);
      this.model.SchoolClassId = parseInt(this.selectedItemsClass[0].ClassId);
      this.spinner.show();
      this.studentService.editStudents(this.model).subscribe(res => {
        this.spinner.hide();
        if (res.status == 2) {
          this.toastr.success(res.content, "Thành công");
          this.router.navigateByUrl('/students');
        }
        else {
          this.toastr.error(res.content, "Thất bại");
        }
      })
    }

  }
  sendActiveCode() {
    debugger
    var model = {
      Id: parseInt(this.route.snapshot.params['Id']),
      ParentId: parseInt(this.selectedItemsParent[0].ParentId),
      IdNo: (this.route.snapshot.params['IdNo']),
      Phone: (this.route.snapshot.params['Phone']),
    }
    this.spinner.show();
    this.studentService.sendActiveCodeForStudents(model).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/students');
      }
    })
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

}
