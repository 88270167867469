import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  constructor(private router: Router, private dialog: MatDialog) {

  }
  ngOnInit(): void {
    debugger
    const token: string = localStorage.getItem("jwt")
    if (token == null || token == "") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  
  }
  isUserAuthenticated() {
    const token: string = localStorage.getItem("jwt")
    if (token) {
      return true;
    }
    else {
      return false;
    }
  }
  Logout() {
    localStorage.removeItem("jwt")
  }

}
