import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { TeacherComponent } from './teacher/teacher.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatDialogModule, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { TeacherService } from './serivce/teacher.service';
import { MatTableModule } from '@angular/material/table'
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TeacherCreateDialogComponent } from './teacher/teacher-create-dialog/teacher-create-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { TeacherEditDialogComponent } from './teacher/teacher-edit-dialog/teacher-edit-dialog.component';
import { ClassComponent } from './class/class.component';
import { ClassCreateDialogComponent } from './class/class-create-dialog/class-create-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ClassEditDialogComponent } from './class/class-edit-dialog/class-edit-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ParentsComponent } from './parents/parents.component';
import { ParentsCreateDialogComponent } from './parents/parents-create-dialog/parents-create-dialog.component';
import { ParentsEditDialogComponent } from './parents/parents-edit-dialog/parents-edit-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StudentsComponent } from './students/students.component';
import { CreateStudentDialogComponent } from './students/create-student-dialog/create-student-dialog.component';
import { EditStudentDialogComponent } from './students/edit-student-dialog/edit-student-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TeacherDeleteDialogComponent } from './teacher/teacher-delete-dialog/teacher-delete-dialog.component';
import { ClassDeleteDialogComponent } from './class/class-delete-dialog/class-delete-dialog.component';
import { ParentDeleteDialogComponent } from './parents/parent-delete-dialog/parent-delete-dialog.component';
import { DeleteStudentDialogComponent } from './students/delete-student-dialog/delete-student-dialog.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from "../environments/environment";
import { AngularFireStorageModule } from '@angular/fire/storage';
import { GiftsCreateDialogComponent } from './gifts/gifts-create-dialog/gifts-create-dialog.component';
import { GiftsDeleteDialogComponent } from './gifts/gifts-delete-dialog/gifts-delete-dialog.component';
import { GiftsEditDialogComponent } from './gifts/gifts-edit-dialog/gifts-edit-dialog.component';
import { GiftsComponent } from './gifts/gifts.component';
import { StudentActiveComponent } from './student-active/student-active.component';
import { DeleteStudentActiveDialogComponent } from './student-active/delete-student-active-dialog/delete-student-active-dialog.component';
import { JobsComponent } from './jobs/jobs.component';
import { CreateJobsDialogComponent } from './jobs/create-jobs-dialog/create-jobs-dialog.component';
import { EditJobsDialogComponent } from './jobs/edit-jobs-dialog/edit-jobs-dialog.component';
import { DeleteJobsDialogComponent } from './jobs/delete-jobs-dialog/delete-jobs-dialog.component';
import { CreateTeacherjobsDialogComponent } from './teacherjobs/create-teacherjobs-dialog/create-teacherjobs-dialog.component';
import { EditTeacherjobsDialogComponent } from './teacherjobs/edit-teacherjobs-dialog/edit-teacherjobs-dialog.component';
import { TeacherjobsComponent } from './teacherjobs/teacherjobs.component';
import { DeleteTeacherjobsDialogComponent } from './teacherjobs/delete-teacherjobs-dialog/delete-teacherjobs-dialog.component';
import { WorkreportComponent } from './workreport/workreport.component';
import { CreateWorkreportDialogComponent } from './workreport/create-workreport-dialog/create-workreport-dialog.component';
import { EditWorkreportDialogComponent } from './workreport/edit-workreport-dialog/edit-workreport-dialog.component';
import { DeleteWorkreportDialogComponent } from './workreport/delete-workreport-dialog/delete-workreport-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSpinnerModule } from "ngx-spinner";
import { LoginComponent } from './auth/login/login.component';
import { TicketComponent } from './ticket/ticket.component';
import { TicketCreateDialogComponent } from './ticket/ticket-create-dialog/ticket-create-dialog.component';
import { TicketEditDialogComponent } from './ticket/ticket-edit-dialog/ticket-edit-dialog.component';
import { TicketDeleteDialogComponent } from './ticket/ticket-delete-dialog/ticket-delete-dialog.component';
import { ReportComponent } from './report/report.component';
const routes: Routes = [
  { path: 'class', component: ClassComponent },
  { path: 'CreateClass', component: ClassCreateDialogComponent },
  { path: '', component: HomeComponent },
  { path: 'counter', component: CounterComponent },
  { path: 'fetch-data', component: FetchDataComponent },
  { path: 'teacher', component: TeacherComponent },
  { path: 'CreateTeacher', component: TeacherCreateDialogComponent },
  { path: 'Editteacher', component: TeacherEditDialogComponent },
  { path: 'class', component: ClassComponent },
  { path: 'CreateClass', component: ClassCreateDialogComponent },
  { path: 'EditClass', component: ClassEditDialogComponent },
  { path: 'parents', component: ParentsComponent },
  { path: 'CreateParents', component: ParentsCreateDialogComponent },
  { path: 'EditParent', component: ParentsEditDialogComponent },
  { path: 'students', component: StudentsComponent },
  { path: 'CreateStudents', component: CreateStudentDialogComponent },
  { path: 'EditStudents', component: EditStudentDialogComponent },
  { path: 'gift', component: GiftsComponent },
  { path: 'CreateGift', component: GiftsCreateDialogComponent },
  { path: 'EditGift', component: GiftsEditDialogComponent },
  { path: 'studentActive', component: StudentActiveComponent },
  { path: 'jobs', component: JobsComponent },
  { path: 'Createjobs', component: CreateJobsDialogComponent },
  { path: 'Editjobs', component: EditJobsDialogComponent },
  { path: 'teacherjobs', component: TeacherjobsComponent },
  { path: 'Createteacherjobs', component: CreateTeacherjobsDialogComponent },
  { path: 'Editteacherjobs/:Code/:JobsId/:TeacherId', component: EditTeacherjobsDialogComponent },
  { path: 'workreport', component: WorkreportComponent },
  { path: 'createworkreport', component: CreateWorkreportDialogComponent },
  { path: 'editworkreport', component: EditWorkreportDialogComponent },

  { path: 'ticket', component: TicketComponent },
  { path: 'createticket', component: TicketCreateDialogComponent },
  { path: 'editticket', component: TicketEditDialogComponent },
  { path: 'report', component: ReportComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    TeacherComponent,
    TeacherCreateDialogComponent,
    TeacherEditDialogComponent,
    ClassComponent,
    ClassCreateDialogComponent,
    ClassEditDialogComponent,
    ParentsComponent,
    ParentsCreateDialogComponent,
    ParentsEditDialogComponent,
    StudentsComponent,
    CreateStudentDialogComponent,
    EditStudentDialogComponent,
    TeacherDeleteDialogComponent,
    ClassDeleteDialogComponent,
    ParentDeleteDialogComponent,
    DeleteStudentDialogComponent,
    GiftsCreateDialogComponent,
    GiftsDeleteDialogComponent,
    GiftsEditDialogComponent,
    GiftsComponent,
    StudentActiveComponent,
    DeleteStudentActiveDialogComponent,
    JobsComponent,
    CreateJobsDialogComponent,
    EditJobsDialogComponent,
    DeleteJobsDialogComponent,
    TeacherjobsComponent,
    CreateTeacherjobsDialogComponent,
    EditTeacherjobsDialogComponent,
    DeleteTeacherjobsDialogComponent,
    WorkreportComponent,
    CreateWorkreportDialogComponent,
    EditWorkreportDialogComponent,
    DeleteWorkreportDialogComponent,
    LoginComponent,
    TicketComponent,
    TicketCreateDialogComponent,
    TicketEditDialogComponent,
    TicketDeleteDialogComponent,
    ReportComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    MatStepperModule, MatProgressSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatMenuModule,
    MatPaginatorModule,
    AngularFireStorageModule,
    MatTooltipModule,
    NgxSpinnerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, "cloud"),
    ToastrModule.forRoot(
    ),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    TeacherService
  ],
  entryComponents: [
    TeacherDeleteDialogComponent,
    ClassDeleteDialogComponent,
    ParentDeleteDialogComponent,
    DeleteStudentDialogComponent,
    GiftsDeleteDialogComponent,
    DeleteStudentActiveDialogComponent,
    DeleteJobsDialogComponent,
    DeleteTeacherjobsDialogComponent,
    DeleteWorkreportDialogComponent,
    TicketDeleteDialogComponent,
    LoginComponent  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
