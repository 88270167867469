import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JobsService } from '../../serivce/jobs.service';
import { TeacherService } from '../../serivce/teacher.service';
@Component({
  selector: 'app-edit-jobs-dialog',
  templateUrl: './edit-jobs-dialog.component.html',
  styleUrls: ['./edit-jobs-dialog.component.css']
})
export class EditJobsDialogComponent implements OnInit {
  model = {
    Name: '',
    Description: '',
    FromDate: new Date(),
    stringFromDate: '',
    ToDate: new Date(),
    stringToDate: '',
  }
  constructor(private spinner: NgxSpinnerService,
    private service: JobsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.model.Name = this.route.snapshot.params['Name']
    this.model.Description = (this.route.snapshot.params['Description'])
    this.model.FromDate = (this.route.snapshot.params['FromDate'])
    this.model.ToDate = (this.route.snapshot.params['ToDate'])
    console.log(this.model)
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.Name == '') {
      this.toastr.warning("Chưa nhập tên công việc", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Description == '') {
      this.toastr.warning("Chưa nhập hướng dẫn công việc", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    if (this.Validate() == true) {
      debugger
      var models = {
        Id: parseInt(this.route.snapshot.params['Id']),
        Code: (this.route.snapshot.params['Code']),
        Name: this.model.Name,
        Description: this.model.Description,
        stringFromDate: this.convert(this.model.FromDate),
        stringToDate: this.convert(this.model.ToDate)
      }
      this.spinner.show();

      this.service.editJobs(models).subscribe(res => {
        this.spinner.hide();
        if (res.status == 2) {
          this.toastr.success(res.content, "Thành công");
          this.router.navigateByUrl('/jobs');
        }
        else {
          this.toastr.error(res.content, "Thất bại");
        }
      })
    }
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
