import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../serivce/class.service';
import { ParentsService } from '../../serivce/parents.service';
import { StudentsService } from '../../serivce/students.service';

@Component({
  selector: 'app-create-student-dialog',
  templateUrl: './create-student-dialog.component.html',
  styleUrls: ['./create-student-dialog.component.css']
})
export class CreateStudentDialogComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,

    private studentService: StudentsService,
    private toastr: ToastrService,
    private router: Router,
    private parentService: ParentsService,
    private classService: ClassService,

  ) { }
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  dropdownSettingsParent: IDropdownSettings;
  dropdownSettingsClass: IDropdownSettings;
  dropdownSettingsStarStudent: IDropdownSettings;
  model = {
    FullName: '',
    DateOfBirth: new Date(),
    stringDateOfBirth: '',
    StartDate: new Date(),
    stringStartDate: '',
    EndDate: new Date(),
    stringEndDate: '',
    ClassId: 0,
    ParentId: 0,
    SchoolName: '',
    SchoolClassId: 0,
    IdNo: '',
    ShirtSize: ''
  }
  displayedColumns =
    ['Index', 'BasisName', 'QualityName', 'StarName', 'NoOfDay', 'StartProcessDate',
      'EndProcessDate', 'ProcessHeaderID']
  result;
  dataSource: MatTableDataSource<any>;
  dropdownParents = [];
  dropdownClass = [];
  dropdownStudent = [];
  selectedItemsStudent = [];
  ngOnInit() {
    this.dropdownSettingsParent = {
      singleSelection: true,
      idField: 'ParentId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.dropdownSettingsClass = {
      singleSelection: true,
      idField: 'ClassId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.dropdownSettingsStarStudent = {
      singleSelection: false,
      idField: 'StudentId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.loadDataParents();
    this.loadDataClass();
  }
  loadDataParents() {
    debugger
    this.parentService.getAll().subscribe((res) => {
      this.result = res.data;

      this.dropdownParents = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { ParentId: this.result[i].id, viewValue: this.result[i].fullName };
        this.dropdownParents.push(object);
      }

    });
  }
  loadDataClass() {
    debugger
    this.classService.getAllClass().subscribe((res) => {
      this.result = res.data;

      this.dropdownClass = [];
      console.log(this.result)
      for (var i = 0; i < this.result.length; i++) {
        var object = { ClassId: this.result[i].id, viewValue: this.result[i].name };
        this.dropdownClass.push(object);

      }

    });
  }
  changeClass() {
    var models = {
      SchoolClassId: this.model.ClassId[0].ClassId
    }
    debugger
    this.studentService.getAllStarClass(models).subscribe((res) => {
      this.result = res.data;
      this.dropdownStudent = [];
      this.loadDatasource();
    });
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.FullName == '') {
      this.toastr.warning("Chưa nhập Họ tên học viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }

    if (this.model.ParentId == 0 || this.model.ParentId == null) {
      this.toastr.warning("Bạn chưa chọn phụ huynh cho học viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.ClassId == 0 || this.model.ClassId == null) {
      this.toastr.warning("Bạn chưa lớp cho học viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    debugger
    /*    if (this.Validate() == true) {*/
    this.model.stringDateOfBirth = this.convert(this.model.DateOfBirth);
    this.model.stringEndDate = this.convert(this.model.EndDate);
    this.model.stringStartDate = this.convert(this.model.StartDate);
    this.model.ParentId = parseInt(this.model.ParentId[0].ParentId);
    this.model.SchoolClassId = parseInt(this.model.ClassId[0].ClassId);
    this.spinner.show();
    this.studentService.createStudents(this.model).subscribe(res => {
      this.spinner.hide();

      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/students');
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
    /*   }*/

  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


}

