import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GiftsService } from '../../serivce/gifts.service';
import { StudentsService } from '../../serivce/students.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
export interface Array {

  value: string;
  viewValue: string;

}
@Component({
  selector: 'app-gifts-create-dialog',
  templateUrl: './gifts-create-dialog.component.html',
  styleUrls: ['./gifts-create-dialog.component.css']
})
export class GiftsCreateDialogComponent implements OnInit {
  dropdownStudent: Array[] = [];
  downloadURL: Observable<string>;
  model = {
    Title: '',
    Description: '',
    StudentId: 0
  }
  fb;
  constructor(
    private spinner: NgxSpinnerService,
    private studentsService: StudentsService,
    private giftsService: GiftsService,
    private toastr: ToastrService,
    private router: Router,
    private storage: AngularFireStorage) { }

  result;
  ngOnInit() {
    debugger
    this.loadStudents();
  }
  loadStudents() {
    this.studentsService.getListStudents().subscribe((res) => {
      this.result = res.data;
      this.dropdownStudent = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { value: this.result[i].id, viewValue: this.result[i].fullName };
        this.dropdownStudent.push(object);
      }
    });
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.Title == '') {
      this.toastr.warning("Chưa nhập tiêu đề quà tặng", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Description == '') {
      this.toastr.warning("Chưa nhập mô tả quà tặng", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.StudentId == 0 || this.model.StudentId == null) {
      this.toastr.warning("Chưa chọn học viên", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }

    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    if (this.Validate() == true) {
      var models = {
        Title: this.model.Title,
        Description: this.model.Description ,
        Image: this.fb,
        StudentId: this.model.StudentId
      }
      this.spinner.show();
      this.giftsService.createGift(models).subscribe(res => {
        this.spinner.hide();
        if (res.status == 2) {
          this.toastr.success(res.content, "Thành công");
          this.router.navigateByUrl('/gift');
        }
        else {
          this.toastr.error(res.content, "Thất bại");
        }
      })
    }

  }
  onFileSelected(event) {

    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `RoomsImages/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`RoomsImages/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
              $('#img').attr("src", url);
            }

            console.log(this.fb);
          });
        })
      )
      .subscribe(url => {
        //if (url) {
        //  this.urls = url.toString();
        //  console.log(this.urls);
        //}
      });
  }
}
