import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from '../../serivce/teacher.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { JobsService } from '../../serivce/jobs.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-create-jobs-dialog',
  templateUrl: './create-jobs-dialog.component.html',
  styleUrls: ['./create-jobs-dialog.component.css']
})
export class CreateJobsDialogComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    private service: JobsService,
    private toastr: ToastrService,
    private router: Router
  ) { }
  model = {
    Name: '',
    Description: '',
    FromDate: new Date(),
    stringFromDate: '',
    ToDate: new Date(),
    stringToDate: '',
  }
  ngOnInit() {
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.Name == '') {
      this.toastr.warning("Chưa nhập tên công việc", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.Description == '') {
      this.toastr.warning("Chưa nhập hướng dẫn công việc", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    if (this.Validate() == true) {
      var models = {
        Name: this.model.Name,
        Description: this.model.Description,
        stringFromDate: this.convert(this.model.FromDate),
        stringToDate: this.convert(this.model.ToDate)
      }
      this.spinner.show();
      this.service.createJobs(models).subscribe(res => {
        this.spinner.hide();
        if (res.status == 2) {
          this.toastr.success(res.content, "Thành công");
          this.router.navigateByUrl('/jobs');
        }
        else {
          this.toastr.error(res.content, "Thất bại");
        }
      })
    }
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
