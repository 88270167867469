import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { StudentactiveService } from '../serivce/studentactive.service';
import { DeleteStudentActiveDialogComponent } from './delete-student-active-dialog/delete-student-active-dialog.component';

@Component({
  selector: 'app-student-active',
  templateUrl: './student-active.component.html',
  styleUrls: ['./student-active.component.css']
})
export class StudentActiveComponent implements OnInit {

  constructor(
    private studentService: StudentactiveService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataResult;
  displayedColumns =
    ['Index', "IDNo", 'ActivationCode', 'ParentName', 'PhoneNumber', 'FullName', 'Actions']
  loading: boolean = false;

  ngOnInit(): void {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loaddata();
      });
    }
    else {
      this.loaddata();
    }
  }
  loaddata() {
    debugger
    this.studentService.getListActive().subscribe(res => {
      this.dataResult = res.data;
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteStudentActive(id) {
    var model = {
      Id: id,
    }
    const dialogref = this.dialog.open(DeleteStudentActiveDialogComponent, {
      width: '500px',
      data: { model }
    });
    debugger
    dialogref.afterClosed().subscribe(result => {
  
      this.loaddata();
    });
  }
}
