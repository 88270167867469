import { EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { MatDialog, MatStepper, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../auth/login/login.component';
import { TeacherService } from '../serivce/teacher.service';
import { TicketService } from '../serivce/ticket.service';
import { TicketDeleteDialogComponent } from './ticket-delete-dialog/ticket-delete-dialog.component';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {
  @Output() public onUpload = new EventEmitter();
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @ViewChild(MatSort, null) sort: MatSort;
  Teacher: any = []
  dataSource: MatTableDataSource<any>;
  dataResult;
  displayedColumns =
    ['Index', "Title", 'Content', 'TypeTicket', 'Status', 'ContentFeedback', 'CreatedBy', 'Actions']
  loading: boolean = false;
  constructor(private service: TicketService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    debugger
    var token = localStorage.getItem("jwt")
    if (token == null || token == "" || token.toString() == "null") {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().subscribe(result => {
       
        this.loaddata();
      });
    }
    else {
      this.loaddata();
    }
  }
  loaddata() {
    debugger
    this.service.getAll().subscribe(res => {
      debugger
      this.dataResult = res.data;
      this.loadDatasource();
    })
  }
  loadDatasource() {
    this.dataSource = new MatTableDataSource(this.dataResult);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  deleteTicket(id) {
    debugger
    var model = {
      Id: id
    }
    const dialogref = this.dialog.open(TicketDeleteDialogComponent, {
      width: '500px',
      data: { model }
    });
    dialogref.afterClosed().subscribe(result => {
      this.loaddata();
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
