import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../serivce/class.service';

@Component({
  selector: 'app-class-delete-dialog',
  templateUrl: './class-delete-dialog.component.html',
  styleUrls: ['./class-delete-dialog.component.css']
})
export class ClassDeleteDialogComponent implements OnInit {
  datas: any;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private classService: ClassService,
    private spinner: NgxSpinnerService
  ) {
    this.datas = data;
  }
  ngOnInit() {
  }
  delClass() {
    this.spinner.show();
    debugger
    var model = { Id: this.datas.model.Id }
    this.classService.delClass(model).subscribe(res => {
      this.spinner.hide();
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.dialogRef.close();
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
