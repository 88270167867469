import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../serivce/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  invalidLogin: boolean;
  model = {
    userName: '',
    passWord: ''
  }
  constructor(private router: Router, private service: LoginService,
    public dialogRef: MatDialogRef<any>, private toastr: ToastrService) { }
  onNoClick() {
    this.dialogRef.close();
  }
  onSubmit() {
    debugger
    this.service.loginUser(this.model).subscribe(res => {
      if (res.status == 2) {
        const token = (<any>res).token;
        localStorage.setItem('jwt', token);
        this.invalidLogin = false;
        this.dialogRef.close();
        this.toastr.success(res.content, "Thành công");
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    }, err => {
      this.invalidLogin = true;
    })
  }
}

