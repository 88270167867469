import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../serivce/class.service';
import { TeacherService } from '../../serivce/teacher.service';
export interface Array {
  value: string;
  viewValue: string;
}
export interface ArrayTeacher {

  TeacherId: string;
  viewValue: string;

}
@Component({
  selector: 'app-class-edit-dialog',
  templateUrl: './class-edit-dialog.component.html',
  styleUrls: ['./class-edit-dialog.component.css']
})
export class ClassEditDialogComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private classService: ClassService,
    private teacherService: TeacherService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService) { }
  model = {
    Id: 0,
    Code: '',
    Name: '',
    OpeningDay: new Date(),
    FullName: '',
    NameStar: '',
    StarId: 0,
    TeacherId: 0
  }
  result;
  star: Array[] = [];
  teacher: ArrayTeacher[] = [];
  dropdownStar = [];
  dropdownTeacher = [];
  selectedItemsStar = [];
  selectedItemsTeacher = [];
  dropdownSettingsStar: IDropdownSettings;
  dropdownSettingsTeacher: IDropdownSettings;

  ngOnInit() {


    this.model.Id = parseInt(this.route.snapshot.params['Id'])
    this.getInfo(this.model.Id);

    this.dropdownSettingsStar = {
      singleSelection: false,
      idField: 'StarId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.dropdownSettingsTeacher = {
      singleSelection: false,
      idField: 'TeacherId',
      textField: 'viewValue',
      selectAllText: 'Tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      itemsShowLimit: 42,
      allowSearchFilter: true
    };
    this.loadDataStar();
    this.loadDataTeacher();
    this.loadDataTeacherfigurant();

  }
  getInfo(models) {
    var modelGetInfo = {
      Id: models
    }
    this.classService.GetInfo(modelGetInfo).subscribe((res) => {

      this.model = res.data.listO_SchoolClass;
      this.model.Code = res.data.listO_SchoolClass.code
      this.model.Name = res.data.listO_SchoolClass.name

      this.model.OpeningDay = (this.route.snapshot.params['OpeningDay'])
      this.model.FullName = (this.route.snapshot.params['FullName'])
      this.model.StarId = parseInt(this.route.snapshot.params['StarId'])
      this.model.TeacherId = parseInt(this.route.snapshot.params['TeacherId'])
      //Sao lớp học

      if (res.data.listO_SchoolClassStar.length > 0) {
        this.selectedItemsStar = [];
        for (var i = 0; i < res.data.listO_SchoolClassStar.length; i++) {
          var object = { StarId: res.data.listO_SchoolClassStar[i].starId, viewValue: res.data.listO_SchoolClassStar[i].name };
          this.selectedItemsStar.push(object);
        }
      }
      //Giáo viên phụ
      debugger
      if (res.data.listO_SchoolClassTeacher.length > 0) {
        this.selectedItemsTeacher = [];
        for (var i = 0; i < res.data.listO_SchoolClassTeacher.length; i++) {
          var objects = { TeacherId: res.data.listO_SchoolClassTeacher[i].teacherId, viewValue: res.data.listO_SchoolClassTeacher[i].fullName };
          this.selectedItemsTeacher.push(objects);
        }
      }
    });
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  loadDataStar() {

    this.classService.getAll().subscribe((res) => {
      this.result = res.data;

      this.dropdownStar = [];

      for (var i = 0; i < this.result.length; i++) {
        var object = { StarId: this.result[i].id, viewValue: this.result[i].name };
        this.dropdownStar.push(object);
      }
      console.log(this.dropdownStar)

    });
  }
  loadDataTeacher() {

    this.teacherService.getListTeacher().subscribe((res) => {
      this.result = res.data;
      this.teacher = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { TeacherId: this.result[i].id, viewValue: this.result[i].fullName };
        this.teacher.push(object);
      }
    });
  }
  loadDataTeacherfigurant() {

    this.teacherService.getListTeacherfigurant().subscribe((res) => {
      this.result = res.data;
      this.dropdownTeacher = [];
      for (var i = 0; i < this.result.length; i++) {
        var object = { TeacherId: this.result[i].id, viewValue: this.result[i].fullName };
        this.dropdownTeacher.push(object);
      }
    });
  }
  Validate() {
    var ErrorCount = 0
    let regexp = new RegExp('^[0-9]{10}$');
    let regexpNumber = new RegExp('^[0-9]*$');
    if (this.model.Name == '') {
      this.toastr.warning("Chưa nhập Tên Lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.selectedItemsStar.length == 0 || this.selectedItemsStar == null) {
      this.toastr.warning("Bạn chưa chọn sao cho lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.selectedItemsTeacher.length == 0 || this.selectedItemsTeacher == null) {
      this.toastr.warning("Bạn chưa chọn giáo viên phụ cho lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (this.model.TeacherId == 0) {
      this.toastr.warning("Bạn chưa chọn giáo viên chính cho lớp", "Thiếu dữ liệu");
      ErrorCount = ErrorCount + 1;
    }
    if (ErrorCount == 0) {
      return true;
    }
    else return false;
  }
  onSubmit() {
    if (this.Validate() == true) {
      debugger
      var models = {
        Id: parseInt(this.route.snapshot.params['Id']),
        Name: this.model.Name,
        stringOpeningDay: this.convert(this.model.OpeningDay),
        StarId: this.model.StarId,
        TeacherId: this.model.TeacherId,
        selectedItemsStar: this.selectedItemsStar,
        selectedItemsTeacher: this.selectedItemsTeacher
      }
      this.spinner.show();
      this.classService.editClass(models).subscribe(res => {
        this.spinner.hide();
        if (res.status == 2) {

          this.toastr.success(res.content, "Thành công");
          this.router.navigateByUrl('/class');
        }
        else {
          this.toastr.error(res.content, "Thất bại");
        }
      })
    }

  }
  sendActiveCode() {
    var models = {
      Id: parseInt(this.route.snapshot.params['Id'])
    }
    this.classService.sendActiveCode(models).subscribe(res => {
      if (res.status == 2) {
        this.toastr.success(res.content, "Thành công");
        this.router.navigateByUrl('/class');
      }
      else {
        this.toastr.error(res.content, "Thất bại");
      }
    })
  }

}
