import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  readonly APIUrl = "https://api.stnhd.com/api/"
  //readonly APIUrl = "https://localhost:44301/api/"

  constructor(private http: HttpClient) { }
  getAll(): Observable<any> {
    return this.http.get<any[]>(this.APIUrl + "Jobs/getAllJobs");
  }
  createJobs(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Jobs/CreateJobs", model);
  }
  delJobs(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Jobs/DelJobs", model);
  }
  GetAllJobById(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Jobs/GetAllJobById", model);
  }
  GetInfoJobs(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Jobs/GetInfoJobs", model);
  }
  editJobs(model): Observable<any> {
    return this.http.post<any[]>(this.APIUrl + "Jobs/editJobs", model);
  }

}
